@import '../../util/layout';
@import '../../util/colors';

//used for navbar and footer
.my-menu {
  background-color: $lightgrey;
  flex-direction: row;
  justify-content: space-between;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.navbar.my-menu {
  position: fixed;
  z-index: 3;
  margin-top: 3.79vh;
  flex-wrap: nowrap;
  width: calc(87% - 1rem);
  padding-left: 14vw;

  &.scrolled {
    margin-top: 0 !important;
  }

  .navbar-brand {
    width: 100%;
    display: flex;
    flex-direction: column;

    .brand-name {
      display: flex;
      flex-direction: column;
      width: fit-content;
      width: -moz-fit-content;
      text-decoration: none;
      color: $almostBlack
    }

    .church-name {
      height: 7.5rem;
      padding: .5rem 0;
    }
  }

  .navbar-nav.desktop-nav .link-list {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 1rem;

    .nav-item {
      width: fit-content;
      width: -moz-fit-content;

      a {
        color: $black;
      }

      .nav-link {
        padding: 0.5rem 0.8rem !important;
        margin: 0 0.2rem;
        border-radius: 0.7rem;
        white-space: pre;

        &:hover, &.active {
          background-color: var(--second-primary-color);
          color: $lightgrey;
          opacity: 0.8;
        }
      }
    }
  }

  .live-btn {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    font-size: 1rem;
    color: $white;
    border-radius: 1rem;
    background-color: $light-grey;
    padding: 0.4em 0.8em;
    cursor: pointer;
    transition: 0.3s;

    i {
      margin-right: 0.4em;
      &:before {
        font-size: 1.4em;
        vertical-align: -0.25em;
      }
    }

    &.live {
      background-color: $yRed;
      &:hover {
        transform: scale(1.2) perspective(1px);
      }
    }
  }

  .navbar-toggler {
    display: none;
  }

  .menu-btn {
    background-color: var(--second-primary-color);
    -webkit-mask: url(../../assets/mobile_menu_toggle.svg) no-repeat right;
    mask: url(../../assets/mobile_menu_toggle.svg) no-repeat right;
    height: 1.2rem;
    width: 1.4rem;
  }
}

// when the sidebar menu is opened
.blur-content.blurred {
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

//landscape
@media screen and (min-aspect-ratio: 13/9) {
  .navbar.my-nav {
    margin-top: 1vh;
  }
}

@media (max-width: $min-phone-width) {
  .navbar.my-nav {
    padding-left: 7vw;
    width: calc(87% - 0.625rem);

    .desktop-nav {
      display: none
    }

    .live-btn {
      top: 0.3rem;
      padding: 0.2rem 0.6rem;
      font-size: 0.8rem;
    }

    .navbar-brand {
      width: 50%;
      .church-name {
        height: 5rem;
      }
    }

    .navbar-toggler {
      padding: 0;
      margin-right: 6vw;
      display: block;
      border: none;
    }
  }
}

@media (max-width: $min-phone-width) {
  .sidebar-description, .navbar-resources-title, .navbar-collapse .navbar-resources .social-media {
    display:none
  }
}
