@import '../../util/layout';
@import '../../util/colors';

.page-footer {
  position: absolute;
  width: calc(100% - 1rem);
  font-size: 0.8em;

  .emptyDiv {
    height: 2em;
    width: 100%;
  }
  a {
    text-align: left;
    display: block;
    text-decoration: none;
    color: $almostBlack;

    &:hover {
      color: $almostBlack;
      text-decoration: underline;
    }
  }

  .container-fluid {
    padding: 3.7vh 0 !important;

    .social-media {
      display: flex;
      align-items: center;
      padding-left: 14vw;

      p {
        font-weight: bold;
        color: $almostBlack;
        font-size: 0.93rem;
        margin-right: 1.72vw;
      }
      a {
        width: fit-content;
        padding-bottom: 0.8125rem;
        font-size: 1rem;

        .icon-text {
          margin-left: 1em;
        }
      }

      .instagram-icon-container {
        display: inline;

        i:before {
          font-size: 1em;
          vertical-align: -0.2em;
        }

        h3 {
          background: #f09433;
          background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
          filter: "progid: DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1)";
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
          padding-right: 1px;
          font-size: 1.4rem;
        }
      }

      .bi-youtube {
        color: $yRed
      }
      .bi-facebook {
        color: $facebookBlue
      }
      i:before {
        font-size: 1.4em;
        vertical-align: -0.3em
      }
    }
  }

  .footer-copyright {
    margin: 2.3vh 0 0 7vw;
    font-size: 0.6875rem;
    text-align: left;
    display: block;

    a {
      padding: 0;
      width: fit-content;
      display: inline-block;
    }
  }

  .links {
    a {
      padding: 0 0 0.625vw 1.57vh;
      position: relative;
      font-size: 1rem;
    }

    ul {
      margin-bottom: 0;
    }

    .line {
      &:before {
        background-color: var(--primary-color);
        content: '';
        position: absolute;
        top: 3px;
        left: -4px;
        width: 6px;
        height: 1rem;
        border-radius: 15px;
      }

      &.longer {
        position: relative;

        &:before {
          height: calc(100% - 1rem);
        }
      }
    }

    .last-one {
      margin-top: 3.6vh;
      &:before {
        height: 6px;
        border-radius: 50%;
        top: 9px;
      }
    }
  }
}

@media (max-width: $min-phone-width) {
  .page-footer {
    margin-right: 0.625rem;

    .links, .social-media p {
      display: none
    }

    .container-fluid {
      padding: 2.8vh 0 !important;

      .social-media {
        width: fit-content;
        margin: 0 auto;
        padding-left: 0;

        a {
          margin-left: unset
        }
      }

      .footer-copyright {
        text-align: center;
        padding: 0 9.72vw;
        margin: 1vh 0 0 0;

        a {
          margin: 0 auto;
          display: block;
        }

        .col-md-8 {
          width: 100% !important;
        }
      }
    }
  }
}
