@import '../../util/colors';
@import '../../util/layout';

.about-page {
  .background-image {
    &.sec19 {
      filter: grayscale(100%) sepia(20%) contrast(1.5) brightness(90%);
    }
    &.sec20 {
      filter: sepia(60%) brightness(120%);
    }
    &.sec20-1,
    &.sec20-2 {
      filter: invert(15%);
    }
  }

  .history {
    display: flex;
    margin: 0 7vw 6rem 7vw;

     .period-list {
       margin-right: 3.5rem;

       .period {
         background-color: $white;
         color: $black;
         cursor: pointer;
         width: 12rem;
         box-shadow: 0px 3px 6px $almostWhite;
         border-radius: 12px;
         font-size: 1.6rem;
         text-align: center;
         margin-bottom: 2.5rem;
         transition: all 0.5s;

         &.active {
           background-color: var(--second-primary-color);
           color: $white
         }
       }
     }
    .description {
      color: $black;
      backdrop-filter: brightness(2) blur(30px);
      border-radius: 2rem;
      padding: 1.4rem 1.8rem;
      height: fit-content;
      overflow: auto;
    }
  }

  .gallery-section {
    display: flex;
    padding: 3rem 0;
    align-items: center;

    .img-container {
      margin-right: 6rem;

      img {
        width: 14rem;
        height: 14rem;
        object-fit: cover;
        cursor: pointer;
        border-radius: 29px;
        transition: transform 0.2s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .description {
      position: relative;
    }
  }

  &.page-content.gallery-open {
    min-height: 100%;

    .history, .info-section {
      display: none;
    }
  }
}


@media (max-width: $min-phone-width) {
  .about-page {
    .history {
      flex-direction: column;
      margin: 0 0 4rem 0;

      .period-list {
        margin: 0 0 3rem 0;
        display: flex;
        overflow: scroll;
        scrollbar-width: none; // firefox
        &::-webkit-scrollbar {
          display: none;
        }

        .period {
          min-width: 8rem;
          width: fit-content;
          margin: 0 0.5rem;
          padding: 0.3rem;
          white-space: nowrap;
          font-size: 1rem;
        }
      }
    }

    .gallery-section {
      flex-direction: column;

      .description {
        order: 1;
        padding-left: 1.5rem;

        .design-lines {
          left: 0;
        }
      }

      .img-container {
        order: 2;
        margin: 2rem auto 0 auto;

        img {
          width: 12rem;
          height: 12rem;
        }
      }
    }
  }
}
