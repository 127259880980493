@import '../../util/layout';
@import '../../util/colors';

.menu-window {
  .close-nav {
    position: fixed;
    left: -100%;
    top: 1rem;
    color: $lightgrey;
    width: 3rem;
    height: 3rem;
    padding: 0.6rem;
    border-radius: 50%;
    background-color: var(--second-primary-color);
    font-size: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    transition: left 500ms ease-in-out;
    z-index: 10;

    &.show {
      left: 4%;
      transition: left 300ms ease-in-out;
    }
  }

  .navbar-collapse {
    position: fixed;
    z-index: 10;
    top: 0;
    right: -100%;
    padding: 0 1rem 1rem 1rem;
    width: 80%;
    height: 100%;
    background-image: linear-gradient(-45deg, var(--primary-color) 0%, var(--second-primary-color) 100%);
    border-radius: 2.8rem 0 0 2.8rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: left;
    transition: right 300ms ease-in-out;
    overflow-y: scroll;
    scrollbar-width: none; // firefox

    &::-webkit-scrollbar {
      display: none;
    }
    &.show {
      right: 0;
      transition: right 300ms ease-in-out;
    }
    .navbar-content {
      height: fit-content;
      width: 100%;
      overflow-y: scroll;
      padding-left: 1rem;
      display: flex;

      &::-webkit-scrollbar {
        display: none
      }

      .navbar-nav {
        color: $lightgrey;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 6vh 0;
        justify-content: space-between;

        .nav-link {
          color: $lightgrey;
          margin: 2px 0;

          &.active {
            background-color: $lightgrey;
            opacity: 0.5;
            color: $black !important;
          }
        }

        .nav-church-logo {
          width: 4rem;
          padding: 1em 0
        }
      }

      .navbar-resources {
        padding: 6vh 0;
        margin-left: 2rem;
        flex: 1;

        .resources-list:before, .navbar-resources-title:before {
          background-color: $lightgrey;
          content: '';
          position: absolute;
          top: 1vh;
          left: -4px;
          width: 6px;
          height: 94%;
          border-radius: 15px;
        }
        .navbar-resources-title:before {
          top: 0;
        }

        .navbar-resources-title {
          position: relative;
          color: $lightgrey;
          text-align: left;
          padding: 0 1rem;
          margin: 0.5rem 0;
        }

        .resources-list {
          position: relative;
        }

        .social-media {
          margin-top: 13.5vh;
        }
      }

      .nav-item a, .navbar-resources a {
        color: $lightgrey !important;
        display: block;
        text-decoration: none;
        text-align: left;
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 0.7rem;
      }
    }

    .nav-link:hover, .resources-list a:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $min-phone-width) {
  .menu-window .navbar-collapse {
    padding-left: 0.5rem !important;

    .navbar-content {
      flex-direction: column;
      min-height: 100%;

      .navbar-nav {
        padding: 3vh 0;

        .nav-link {
          padding: 0.3rem 0.5rem !important;
        }
      }

      .navbar-resources {
        margin: 0 !important;

        a {
          padding: 0.3rem 0.5rem !important;
        }
      }

    }
  }
}
