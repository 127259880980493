@import '../../util/colors';
@import '../../util/layout';

.program-day {
  text-align: left;
  margin-bottom: 4.9vh;

  .bold {
    font-weight: bold;
  }
  .day-title {
    color: var(--accent-color);

    .day-name {
      font-size: 2.125rem;
      line-height: 2.31rem;
    }
  }

  .bi-sunset::before {
    color: var(--primary-color);
    font-size: 2rem;
    vertical-align: -0.7rem;
  }
  .program-line {
    p {
      display: inline;
      font-weight: bold;
    }
    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $min-phone-width) {
  .program-day {
    text-align: center;

    .day-title .day-date {
      display: block;
    }
  }
}
