@import '../../util/colors';
@import '../../util/layout';

.home-page {
  .fullscreen-wrapper {
    height: calc(var(--app-height) - 28.15vh);;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .parag {
      color: $black;
      font-size: 1.125rem;
      line-height: 1.312rem;
      margin: 3.33vh 4vw 3.33vh 0;
    }
    .scroll-arrow {
      width: 6.25rem;
      height: 6.25rem;
      opacity: 0.7;
      background-color: $lightgrey;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 auto 5vh auto;
      text-align: center;

      .bi-chevron-down::before {
        color: var(--primary-color);
        font-size: 4.375rem;
        margin-top: 0.9375rem;
      }
    }
  }
  .description {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    margin: 4.5vh 0 4vh 0;
    position: relative;
    color: $black;
    white-space: pre-wrap;

    .moreButton, .lessButton {
      display: none
    }

    .design-lines {
      height: 40%;
    }
  }

  .program-section {
    display: flex;
    margin: 6.5vh 0 1.85vh 0;
    justify-content: space-between;

    .program-day {
      max-width: 40%;
    }
    .next {
      color: var(--accent-color);
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
}


@media (max-width: $min-phone-width) {
  .home-page {
    .fullscreen-wrapper {
      .parag {
        margin-right: 0;
      }

      .scroll-arrow {
        width: 3.75rem;
        height: 3.75rem;

        .bi-chevron-down::before {
          font-size: 2.5rem;
          margin-top: 0.6rem;
        }
      }
    }

    .description {
      .text{
        margin-bottom: 2vh;
      }
      &.closed {
        .text {
          max-height: 31vh;
          overflow: hidden;
          padding-left: 1.5rem;
          mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
        }
        .design-lines {
          left: 0;
          height: 80%;
        }
      }
      &.open {
        .text {
          height: fit-content;
          max-height: 500vh;
          transition: max-height 1s ease-in-out;
        }
        .design-lines {
          display: none
        }
      }

      &.closed .moreButton, &.open .lessButton {
        display: block;
        cursor: pointer;
        margin: 0 auto;
        border: solid 2px var(--accent-color);
        border-radius: 21px;
        color: black;
        font-size: 1.12rem;
        line-height: 2.56rem;
        height: 2.56rem;
        padding: 0 4.8vw;
        width: fit-content;
        width: -moz-fit-content;
      }
    }

    .program-section {
      flex-direction: column;
      margin: 5.4vh 0 1vh 0;

      .program-day {
        max-width: 100%;
      }

      .next {
        margin-bottom: 3vh;
      }
    }
  }
}
