@import '../../util/colors';
@import '../../util/layout';

.contact-page {

  .contact-details {
    font-size: 1.25rem;
    line-height: 1.68rem;
    padding-left: 1.35vw;

    .line {
      display: inline-flex;
      width: 100%;
      text-align: left;
    }

    p, a {
      margin-bottom: 1.125rem;
      text-decoration: none;
      color: $black;
    }

    .section {
      width: 5.5rem;
      color: var(--accent-color);
    }

    .value {
      font-weight: bold;
      color: $black;
    }
  }

  iframe {
    width: 100%;
    border-radius: 1.5rem;
  }

  .contact-form {
    padding: 0 3.75vw;

    .form-line {
      display: flex;
      justify-content: space-between;
      .my-input {
        width: 45%;
      }
    }

    .default-red-button {
      font-size: 1.93rem;
      line-height: 2.375rem;
      padding: 1vh 3vw;
      margin: 0 auto 1.75vh auto;
      &.disabled {
        position: relative;
        color: transparent;
      }

      .spinner-border {
        color: $white;
        top: calc(50% - 1rem);
        left: calc(50% - 1rem)
      }
    }
  }
}

@media (orientation: landscape) {
  .contact-page .contact-details .desktop-break {
    display: inline-block !important;
  }
}

@media (max-width: $min-phone-width) {
  .contact-page {
    .default-container {
      padding-left: 0;
      padding-right: 0;
    }

    .contact-details {
      padding-left: 7vw;
      padding-right: 7vw;

      .line{
        display: block;
        margin-bottom: 1.375rem;
        text-align: center;
      }
      p, a {
        margin-bottom: 0;
      }

      .section {
        width: 100%;
      }

      .desktop-break {
        display: none;
      }
    }

    .contact-form {
      padding: 0 8.5vw;

      .form-line {
        display: block;

        .my-input{
          width: 100%;
        }
      }

      .default-red-button {
        font-size: 1.31rem;
        line-height: 1.56rem;
        padding: 1.84vh 9.73vw;
        margin: 0 auto 2vh auto;
      }
    }

    iframe {
      border-radius: 0;
    }
  }
}
