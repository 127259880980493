@import '../../util/colors';
@import '../../util/layout';

.event-page {
  padding-top: 22vh;

  .left-title-section {
    margin: 10vh 14vw 0 14vw;
    border-radius: 22px;
    padding-left:4vw;
    width: calc(100% - 28vw);
    text-align: center;

    .event-date-type {
      display: flex;
      gap: 3rem;
      font-size: 1.6rem;
      color: $black;
      font-weight: bold;
      justify-content: center;
    }
  }

  .event-content {
    padding: 1.2rem 14vw;
    margin: 10vh 0;
    background-color: $lightgrey;
    border-radius: 28px;
    text-align: justify;
    white-space: pre-wrap;

    .content-intro {
      color: $grey-1;
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
    .content-text {
      color: $almostBlack;
      font-size: 1.2rem;
    }
    .fb-link {
      margin: 1rem 0;
      .bi-facebook {
        color: $facebookBlue;
        margin-right: 1em;
      }
      i:before {
        font-size: 1.4em;
      }
      a {
        width: fit-content;
        text-decoration: none;
        color: $almostBlack;
        &:hover {
          color: $almostBlack;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: $min-phone-width) {
  .event-page {
    .back-btn.top {
      display: none;
    }
    .back-btn.bottom {
      position: absolute;
    }

    .left-title-section {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left:7vw;
      width: calc(100% - 0.6rem);
      text-align: left;

      .event-date-type {
        margin-top: 2.4rem;
        flex-direction: column;
        gap: 0;
        font-size: 1rem;
        justify-content: left;
      }
    }
    .event-content {
      padding: 0.7rem 1.31rem;
      margin-bottom: 18vh;

      .content-intro {
        font-size: 1.1rem;
      }
      .content-text {
        font-size: 0.9rem;
      }
      .fb-link {
        text-align: left;
      }
    }
  }
}
