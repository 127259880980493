@import '../../util/colors';

.toast {
  z-index: 11;
  width: unset;
  background-color: var(--accent-color);
  color: white;
  font-size: 1.3rem;
  left: 43.5%;
  transform: translate(-50%, 0);
  bottom: 2.5rem;
  border-radius: 1.8rem;
  padding: 0 5px;
  text-align: center;

  .btn-close{
    color: $white;
  }
}
