a {
  color: #252525;
}

a:hover {
  color: #f5f5f5;
}


/* Canva */

#gradient-canvas {
  min-height: 100vh;
  // min-height: -webkit-fill-available;
  width: 100%;
  --gradient-color-1: #a6c0e8;
  --gradient-color-2: #f7cdb5;
  --gradient-color-3: #f5f5f5;
  --gradient-color-4: #e8a491;
}


/* Sabbath Column */

.sabbath-column:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(-45deg, #f89f6d 0%, #cd4c27 100%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.sabbath-column:hover:before {
  opacity: 1;
  cursor: pointer;
}


/* Church Name */

.church-name-logo {
  position: absolute;
  left: 13%;
  max-width: 40vh;
  margin: 10px 0 0 0;
}


/* Info Rectangle */

.info {
  position: fixed;
  bottom: 12vh;
  left: 14.28%;
}

.title {
  position: fixed;
  left: 14.28%;
  bottom: 50%;
}

.title h1 {
  color: black;
  font-weight: 600;
}


/* Buttons */

.text-info {
  display: flex;
  text-align: center;
  place-content: left;
  border-radius: 50px;
  font-family: 'Noto Sans';
  margin-top: 15px;
}

.telephone {
  font-size: 2.3vh;
  background-color: #f5f5f5;
  padding: 8px 0 8px 0;
  border-radius: 50px;
  width: 65%;
  transition: all 0.3s ease-out;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
}

.address {
  font-size: 2.3vh;
  background-color: #f5f5f5;
  padding: 8px 18px 8px 18px;
  border-radius: 50px;
  transition: all 0.3s ease-out;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
}

.address:hover {
  background-image: linear-gradient(-45deg, #305580 0%, #466cb4 100%);
  border-radius: 50px;
  text-decoration: none;
}

.telephone:hover {
  background-image: linear-gradient(-45deg, #305580 0%, #466cb4 100%);
  border-radius: 50px;
  text-decoration: none;
}


/* Social Media */

.wrapper {
  display: flex;
  padding-top: 15px;
}

.button:nth-child(1) {
  text-decoration: none;
  color: #252525;
}

.button:nth-child(2) {
  text-decoration: none;
  color: #252525;
}

.button:nth-child(3) {
  text-decoration: none;
  color: #252525;
}

.wrapper .button {
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 0 5px;
  overflow: hidden;
  background: #F5F5F5;
  border-radius: 50px;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-out;
}

.wrapper .button:nth-child(1):hover {
  width: 200px;
}

.wrapper .button:nth-child(2):hover {
  width: 215px;
}

.wrapper .button:nth-child(3):hover {
  width: 160px;
}

.wrapper .button .icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
}

.wrapper .button:nth-child(1):hover .icon {
  background: #FF0000;
}

.wrapper .button:nth-child(2):hover .icon {
  background: #4267B2;
}

.wrapper .button:nth-child(3):hover .icon {
  background: #2196F3;
}

.wrapper .button .icon i {
  font-size: 25px;
  line-height: 60px;
  transition: all 0.3s ease-out;
}

.wrapper .button:hover .icon i {
  color: whitesmoke;
}

.wrapper .button span {
  font-size: 25px;
  font-weight: 400;
  line-height: 60px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}

.wrapper .button:nth-child(1) span {
  color: #E1306C;
}

.wrapper .button:nth-child(2) span {
  color: #4267B2;
}

.wrapper .button:nth-child(3) span {
  color: #2196F3;
}


/* Grid for Developer mode */

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  background-color: #F5F5F5;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  height: 100vh;
  text-align: center;
}


/* Footer */

footer {
  position: absolute;
  bottom: 0vh;
  text-align: initial;
}


/* Copyright */

.copyright {
  max-width: 85vw;
  font-size: 15px;
  cursor: pointer;
  text-decoration: #252525;
  color: #252525;
}

.copyright:hover {
  color: #252525;
  text-decoration: underline;
}


/* --- Media Queries ---- */


/* Extra small devices (portrait phones, less than 576px)*/

@media (max-width: 576px) {
  .symbol {
      max-width: 90%;
  }
  .background-info {
      width: 33ch;
  }
  .title {
      width: 200px;
  }
  .title h1 {
      font-size: 35px;
  }
  .text-info {
      padding-top: 2.5px;
      font-size: 2vh;
  }
  .wrapper .button {
      height: 45px;
      width: 45px;
  }
  .wrapper .button .icon {
      height: 45px;
      width: 45px;
      line-height: 45px;
  }
  .wrapper .button .icon i {
      line-height: 45px;
  }
  .wrapper .button:hover {
      width: 45px;
  }
  .wrapper .button .icon i {
      font-size: 20px;
  }
  .wrapper .button:nth-child(1):hover {
      width: 45px;
  }
  .wrapper .button:nth-child(2):hover {
      width: 45px;
  }
  .wrapper .button:nth-child(3):hover {
      width: 45px;
  }
  .copyright {
      font-size: 10px;
  }
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .symbol {
      max-width: 70%;
  }
  .background-info {
      width: 50ch;
  }
  .title h1 {
      font-size: 40px;
  }
  .text-info {
      font-size: 3vh;
  }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .text-info {
      font-size: 3vh;
  }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .background-info {
      width: 93ch;
  }
  .title h1 {
      font-size: 60px;
  }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .symbol {
      max-width: 40%;
  }
  .background-info {
      width: 109ch;
  }
  .title h1 {
      font-size: 80px;
  }
}
