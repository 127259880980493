@import '../../util/colors';
@import '../../util/layout';


.program-page {
  .week {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    line-height: 2.125rem;
    color: $black;

    .column {
      width: 48%;
    }
    .divider {
      display: none
    }
  }
}

@media (max-width: $min-phone-width) {
  .program-page {
    .week {
      flex-direction: column;

      .column {
        width: 100%;
      }
      .divider {
        display: flex;
        margin-top: 0
      }
    }
  }
}
