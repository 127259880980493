@import '../../util/layout';
@import '../../util/colors';

.design-lines {
  position: absolute;
  top: 0;
  left: -1.5rem;
  height: 100%;

  div {
    border-left: solid 5px var(--second-accent-color);
    border-radius: 15px;
    width: 0;
  }

  .shorter {
    height: 20%;
  }

  .longer {
    margin-top: 1rem;
    height: calc(80% - 1rem);
  }
}
