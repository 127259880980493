$red: #ed390f;
$yRed: #F52929;
$lightgrey: #f5f5f5;
$almostBlack: #252525;
$black: #000000;
$facebookBlue: #4267b2;
$almostWhite: #00000029;
$white: #FFFFFF;
$black: #000000;
$grey: #6c757d;
$grey-1: #929292;
$light-grey: #b3b3b3;
