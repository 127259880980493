@import '../../util/layout';
@import '../../util/colors';

.my-input {
  position: relative;
  display: block;
  padding-bottom: 3.2rem;

  input, textarea, label {
    padding-left: 1.66vw;
    border-radius: 1.8rem;
    font-size: 1.25rem;
    line-height: 2rem;
    border: 0
  }

  input::placeholder, textarea::placeholder {
    color: $black;
    opacity: 0.25;
  }

  input {
    height: 3.37rem;
    width: 100%;

    &:focus {
      border: 3px solid var(--accent-color);
      border-radius: 1.8rem;
      box-shadow: none;
      outline: none;
    }
  }

  textarea {
    height: 30vh;
    width: 100%;
    padding-top: 0.5rem;
    padding-right: 1.66vw;
    scrollbar-width: thin;

    &:focus {
      outline: none;
    }
  }
  .textarea-container {
    border-radius: 1.8rem;
    padding: .3rem 0.6rem .3rem 0;
    background-color: $white;

    &:focus-within {
      border: 3px solid var(--accent-color);
      border-radius: 1.8rem;
    }
  }

  .help-block {
    position: absolute;
    left: 1.66vw;
    bottom: 1.8rem;
    color: $red;
    font-size: 1rem;
  }

  label {
    color: var(--accent-color);
  }
}

@media (max-width: $min-phone-width) {
  .my-input {
    padding-bottom: 2rem;

    input, textarea, label{
      padding-left: 2rem;
    }

    textarea {
      padding-right: 2rem;
    }

    .help-block{
      left: 2rem;
      bottom: 0.5rem;
    }
  }
}
