@import '../../util/layout';
@import '../../util/colors';

.pagination {
  margin-top: 5vh;
  display: flex;
  justify-content: center;

  div {
    height: 2.875rem;
    width: 2.875rem;
    font-size: 26px;
    font-weight: bold;
    color: black;
    margin: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
  }

  .page-number{
    border: solid 4px transparent;
    text-align: center;
  }

  .arrow{
    padding: 2.5px;
  }

  .current {
    border-color: var(--primary-color);
  }
}

@media (max-width: $min-phone-width) {
  .pagination div {
    height: 2.4rem;
    width: 2.4rem;
    font-size: 21px;
    margin: .2rem;
  }
}
