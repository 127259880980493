@import '../../util/layout';
@import '../../util/colors';

.back-btn {
  display: flex;
  border-radius: 22px;
  font-size: 1rem;
  font-weight: bold;
  gap: 0.5rem;
  cursor: pointer;
  width: fit-content;
  width: -moz-fit-content;
  padding: 0.5rem 1.4rem;

  &.top {
    backdrop-filter: brightness(2) blur(30px);
    -webkit-backdrop-filter: brightness(2) blur(30px);
    color: $black;
    margin-left: 14vw;
  }
  &.bottom {
    margin: 7vh 0;
    background-color: var(--primary-color);
    color: $white;
  }
}
