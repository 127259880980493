@import '../../util/layout';
@import '../../util/colors';

.divider {
  width: 10vw;
  margin: 4.9vh auto;
  display: flex;

  div {
    border-bottom: solid 5px var(--second-accent-color);
    border-radius: 15px;
    height: 0;
  }

  .shorter {
    width: 12%;
  }

  .longer {
    margin-left: 12.5%;
    width: 75.5%;
  }
}


@media (max-width: $min-phone-width) {
  .divider {
    width: 37vw;
  }
}
