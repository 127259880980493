@import 'util/colors';
@import 'util/layout';
:root {
  --blurAmount: 0px;
  --scaleAmount: 1;
}

.spinner-border {
  position: absolute;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
}

.App {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.router-content {
  min-height: 100%
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 87%;
  position: relative;

  .background-image {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1;
    background-position: center center;
    background-size: cover;
    filter: blur(var(--blurAmount));
    transform: scale(var(--scaleAmount));
  }
  .page-content {
    min-height: 130vh;
    padding-top: 28.15vh;
    padding-bottom: calc(10vh + 10.18rem);
  }
}

.logo-column {
  width: 13%;
  background-image: linear-gradient(-45deg, var(--primary-color) 0%, var(--second-primary-color) 100%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  right: 0;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background-image: linear-gradient(-45deg, var(--second-primary-color) 0%, var(--primary-color) 100%);
    transition: .3s linear;
    opacity: 0;
  }
  &:hover:before {
    opacity: 1;
  }

  .church-logo {
    position: fixed;
    width: 7.44vw;
    top: 50vh;
    transform: translate(0,-50%);
  }
}

.left-title-section {
  position: relative;
  z-index: 1;
  border-radius: 0 22px 22px 0;
  padding: 2.7vh 4vw 2.7vh 14vw;
  backdrop-filter: brightness(2) blur(30px);
  -webkit-backdrop-filter: brightness(2) blur(30px);
  width: 53%;
  text-align: left;

  &.with-margin {
    margin-bottom: 8.15vh;
  }

  .bold-title {
    text-align: center;
    font-size: 3.68rem;
    line-height: 4.3rem;
    font-weight: bold;
    color: $black;
  }
}

.default-red-button {
  display: block;
  width: fit-content;
  width: -moz-fit-content;
  color: $white;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  padding: 1.38vh 1vw;
  border-radius: 33px;
  background-color: var(--primary-color);
  cursor: pointer;
  border: 0;

  &.disabled {
    background-color: $grey;
    cursor: not-allowed;
    pointer-events: none;
  }

  a, a:hover {
    text-decoration: none;
    color: $lightgrey;
  }
}

.default-container {
  background-color: $lightgrey;
  border-radius: 28px;
  margin: 0 7vw 10vh 7vw;
  padding: 2.77vh 7vw;
}
html {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.App ::-webkit-scrollbar {
  width: 0.5rem;
}
.App ::-webkit-scrollbar-track {
  border-radius: 1rem;
  box-shadow: inset 0 0 5px grey;
}
.App ::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 1rem;
}
// firefox
.App {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) transparent;
}

//mobile landscape
@media (max-width: $min-phone-width) and (min-aspect-ratio: 13/9) {
  .content .page-content {
    padding-top: 33vh;
    min-height: 170vh;
  }

  .spinner-border {
    top: 35%;
  }
}

@media (max-width: $min-phone-width) {
  .left-title-section {
    width:100%;
    border-radius: 0;
    padding: 2.7vh 7vw;

    .bold-title {
      font-size: 1.82rem;
      line-height: 2.25rem;
    }
  }

  .default-red-button {
    font-size: 1rem;
    line-height: 1rem;
    padding: 1.38vh 3.73vw;
  }

  .default-container {
    border-radius: 0;
    margin: 0 0 13vh 0;
  }

  .content .home-page .background-image, .about-page .background-image, .events-page .background-image {
    background-position: 73%;
  }
}
